body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 500px) {
  .MuiDataGrid-panel .MuiPaper-root .MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;

    .MuiDataGrid-filterFormDeleteIcon {
      margin-bottom: 10px;
    }

    .MuiDataGrid-filterFormLinkOperatorInput {
      display: none;
    }

    .MuiDataGrid-filterFormColumnInput {
      margin-bottom: 15px;
    }

    .MuiDataGrid-filterFormOperatorInput {
      margin-bottom: 15px;
    }
  }
}